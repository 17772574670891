<template>
  <div class="home">
    <img class="home-banner" src="@/assets/home/banner.png" alt="" />
    <MyHeader />
    <div class="home-content">
      <swiperInformation />
      <privatization />
      <advantage />
      <version />
      <premium />
      <security />
      <solution />
    </div>
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";
import swiperInformation from "@/components/home/swiperInformation.vue";
import privatization from "@/components/home/privatization.vue";
import advantage from "@/components/home/advantage.vue";
import version from "@/components/home/version.vue";
import premium from "@/components/home/premium.vue";
import security from "@/components/home/security.vue";
import solution from "@/components/home/solution.vue";
import MyFooter from "@/components/MyFooter.vue";

export default {
  name: "HomeView",
  components: {
    MyHeader,
    swiperInformation,
    privatization,
    advantage,
    version,
    premium,
    security,
    solution,
    MyFooter,
  },
};
</script>
<style lang='scss' scoped>
.home-banner {
  height: 657px;
  width: 100%;
  min-width: 1200px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  cursor: pointer;
}
.home-content {
  position: relative;
  margin: 0 auto;
  text-align: center;
  min-width: 1200px;
}
</style>
