<template>
  <div class="module gray">
    <div class="module-content">
      <div class="title">官方精品推荐</div>
      <div class="content">
        <div
          class="content-item"
          v-for="item in premiumList1"
          :key="item.title"
        >
          <img :src="item.img" alt="" />
          <div class="content-item-content">
            {{ item.title }}
          </div>
          <div class="button">查看详情</div>
        </div>
      </div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in premiumList2"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <div class="content-item-content">
            {{ item.title }}
          </div>
          <div class="button">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      premiumList1: [
        {
          img: require("../../assets/home/img_oa.png"),
          title: "【OA办公】考勤打卡、工作汇报、员工档案、客户管理",
        },
        {
          img: require("../../assets/home/img_phpzsff.png"),
          title: "【PHP知识付费】直播教育内容付费在线考试系统",
        },
        {
          img: require("../../assets/home/img_javabzb.png"),
          title: "【JAVA标准版】新零售JAVA 版社交单商户电商商城系统",
        },
        {
          img: require("../../assets/home/img_javadsj.png"),
          title: "【JAVA多商户】多商户JAVA版 B2B2C商家入驻平台系统",
        },
      ],
      premiumList2: [
        {
          img: require("../../assets/home/img_phpsyb.png"),
          title: "【PHP私域版】高性能私域营销电商商城系统",
        },
        {
          img: require("../../assets/home/img_phpddb.png"),
          title: "【PHP多店版】品牌连锁智慧零售电商商城系统",
        },
        {
          img: require("../../assets/home/img_phpbzb.png"),
          title: "【PHP标准版】新零售社交电商商城系统",
        },
        {
          img: require("../../assets/home/img_phpdsj.png"),
          title: "【PHP多商户】商家入驻多业态电商平台系统",
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.module {
  padding-top: 68px;
  .module-content {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      margin-bottom: 68px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 63px;
      .content-item {
        width: 280px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
        padding-bottom: 36px;
        img {
          width: 280px;
          height: 278px;
        }
        .content-item-content {
          width: 250px;
          height: 60px;
          margin: 0 auto;
          margin-top: 6px;
          line-height: 20px;
          text-align: left;
        }
        .button {
          width: 119px;
          height: 35px;
          background: #3778fd;
          border-radius: 100px;
          line-height: 38px;
          text-align: center;
          margin: 0 auto;
          font-size: 12px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
// @media screen and (max-width: 1600px) {
//   .content-item {
//     width: 288px !important;
//     img {
//       width: 288px !important;
//       height: 286px !important;
//     }
//     .content-item-content {
//       width: 240px !important;
//     }
//   }
// }
// @media screen and (max-width: 1440px) {
//   .content-item {
//     width: 268px !important;
//     img {
//       width: 268px !important;
//       height: 266px !important;
//     }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .content-item {
//     width: 248px !important;
//     img {
//       width: 248px !important;
//       height: 226px !important;
//     }
//     .content-item-content {
//       width: 220px !important;
//       font-size: 14px !important;
//     }
//   }
// }
</style>