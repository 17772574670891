<template>
  <div class="module">
    <div class="module-content">
      <div class="title">产品版本介绍</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in versionList"
          :key="index"
        >
          <div class="item-title">
            <img src="@/assets/home/logo.png" class="item-img" />
            {{ item.title }}
            <div class="item-tag">{{ item.tag }}</div>
          </div>
          <!-- <img :src="item.img" alt="" class="image" /> -->
          <div
            class="image"
            :style="{
              background: `url(${item.img})`,
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="content-item-content animate__animated animate__zoomIn">
            {{ item.content }}
            <div class="button">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      versionList: [
        {
          img: require("../../assets/home/img_zyb.png"),
          title: "友书IM专业版系统",
          tag: "专业版",
          content:
            "为企业和团队使用而设计的即时通讯产品，旨在提高内部沟通的效率和便捷性，它具备以下主要功能：单聊功能、群聊功能、群成员保护、消息撤回、已读未读、音视频通话、多设备同步等。",
        },
        {
          img: require("../../assets/home/img_qyb.png"),
          title: "友书IM企业版系统",
          tag: "企业版",
          content:
            "为企业、事业单位、政府部门使用而设计的即时通讯产品，旨在提高内部沟通的效率和安全性，它具备以下主要功能：专业版所有功能,以及端到端加密、截屏保护、阅后即焚、会话密室、组织架构、局域网部署等，以及在线商城、 OA办公、CRM、EPR应用集成。",
        },
        {
          img: require("../../assets/home/img_qjb.png"),
          title: "友书IM旗舰版系统",
          tag: "旗舰版",
          content:
            "为自运营团队和企业使用而设计的即时通讯产品，旨在为客户提供一个私有化高可用的即时通讯产品，它具备以下主要功能：专业版所有功能,以及端到端加密、阅后即焚、钱包红包、靓号功能、会员功能、第三方应用集成等。",
        },
        {
          img: require("../../assets/home/img_jyb.png"),
          title: "友书IM交友版系统",
          tag: "交友版",
          content:
            "为自运营团队和企业使用而设计的即时通讯产品，旨在为客户提供一个私有化高可用的社交交友产品，它具备以下主要功能：专业版所有功能,以及阅后即焚、钱包红包、会员功能、实名认证、一对一视频通话、赠送礼物、分销功能等。",
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.module {
  padding-top: 68px;
}
.module-content {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 40px;
  margin-bottom: 68px;
}
.content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 63px;

  .content-item {
    border-radius: 10px;
    width: 280px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
    line-height: 24px;
    transition: 1s;
    .item-title {
      font-size: 16px;
      width: 260px;
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 10px;
      .item-img {
        height: 50px;
        width: 50px;
        background-color: #2a7efb;
        margin-right: 6px;
      }
      .item-tag {
        width: 56px;
        height: 19px;
        border: 1px solid #2a7efb;
        border-radius: 2px;
        font-size: 12px;
        line-height: 19px;
        color: #2a7efb;
        margin-left: 6px;
      }
    }
    .image {
      width: 260px;
      height: 260px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .content-item-content {
      display: none;
      width: 240px;
      height: 260px;
      color: #888888;
      margin: 0 auto;
      // margin-bottom: 6px;
      line-height: 20px;
      text-align: left;
      --animate-duration: 1s;
    }
    .button {
      width: 119px;
      height: 35px;
      background: #3778fd;
      border-radius: 100px;
      line-height: 38px;
      text-align: center;
      margin: 0 auto;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      margin-top: -40px;
    }
  }
  .content-item:hover {
    transform: translateY(-20px);
    .image {
      display: none;
    }
    .content-item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
// @media screen and (max-width: 1600px) {
//   .content-item{
//     width: 288px !important;
//     img {
//       width: 288px !important;
//       height: 286px !important;
//     }
//     .content-item-content {
//       width: 240px !important;
//     }
//   }
// }
// @media screen and (max-width: 1440px) {
//   .content-item{
//     width: 268px !important;
//     img {
//       width: 268px !important;
//       height: 266px !important;
//     }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .content-item{
//     width: 248px !important;
//     img {
//       width: 248px !important;
//       height: 226px !important;
//     }
//     .content-item-content {
//       width: 220px !important;
//       font-size: 14px !important;
//     }
//   }
// }
</style>