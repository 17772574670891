<template>
  <div class="advantage-module gray">
    <div class="advantage-module-content">
      <div class="title">产品优势功能</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          <div class="item-detail">
            <img :src="item.img" alt="" :style="item.style" />
            <div class="detail">
              <div class="detail-title">{{ item.title }}</div>
              <div class="detail-content">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advantageList: [
        {
          img: require("../../assets/home/icon_duanddjy.png"),
          style: {
            width: "44px",
            height: "50px",
          },
          title: "端到端加密",
          content: "采用端到端加密，确保消息传输过程中的隐私和安全。",
        },
        {
          img: require("../../assets/home/icon_jpbh.png"),
          style: {
            width: "48px",
            height: "67px",
          },
          title: "截屏保护",
          content:
            "支持截屏保护功能，禁止对聊天框进行截屏录屏，保障用户信息的安全和隐私。",
        },
        {
          img: require("../../assets/home/icon_ycxh.png"),
          style: {
            width: "50px",
            height: "43px",
          },
          title: "远程销毁",
          content: "支持远程销毁消息功能，群主可以对群历史消息进行远程销毁。",
        },
        {
          img: require("../../assets/home/icon_sxch.png"),
          style: {
            width: "47px",
            height: "47px",
          },
          title: "双向撤回",
          content:
            "支持双向撤回功能，用户可以清空双方已经发送的单聊信息，避免隐私信息的泄露。",
        },
        {
          img: require("../../assets/home/icon_yyms.png"),
          style: {
            width: "40px",
            height: "51px",
          },
          title: "语音模式",
          content:
            "支持语音模式，在无法打字的情况下，用户可以通过语音进行通讯。",
        },
        {
          img: require("../../assets/home/icon_ddtb.png"),
          style: {
            width: "39px",
            height: "34px",
          },
          title: "多端同步",
          content:
            "支持多端同步，保证用户在不同终端使用该系统可以同步数据，不会出现信息不同步的情况。",
        },
        {
          img: require("../../assets/home/icon_ysqz.png"),
          style: {
            width: "40px",
            height: "44px",
          },
          title: "隐私群组",
          content:
            "支持隐私群组功能，群成员之间不能相互加好友，从而保证通讯的私密性和安全性。",
        },
        {
          img: require("../../assets/home/icon_hhms.png"),
          style: {
            width: "48px",
            height: "52px",
          },
          title: "会话密室",
          content:
            "支持会话密室功能，密室的会话窗口需要输入设置的密码才能进入，保证聊天的安全性。",
        },
        {
          img: require("../../assets/home/icon_xxqr.png"),
          style: {
            width: "44px",
            height: "42px",
          },
          title: "消息确认",
          content:
            "支持消息确认功能，发送方可以获得接收方已经读取了消息的反馈，确保信息的及时和准确性。",
        },
        {
          img: require("../../assets/home/icon_yspth.png"),
          style: {
            width: "46px",
            height: "36px",
          },
          title: "音视频通话",
          content:
            "支持音视频通话功能，单聊和群聊可实现更加快捷和高效的通讯方式。",
        },
        {
          img: require("../../assets/home/icon_lhgn.png"),
          style: {
            width: "49px",
            height: "47px",
          },
          title: "靓号功能",
          content:
            "支持靓号功能，用户可以购买ID靓号和群靓号，来展示自己的身份、个性或品牌形象。靓号通常是一组易记的数字或字母组合，如：88888、abcd。",
        },
        {
          img: require("../../assets/home/icon_hygn.png"),
          style: {
            width: "38px",
            height: "36px",
          },
          title: "会员功能",
          content:
            "支持会员制度，用户可以通过缴纳会员费用，享受更多的会员特权功能。",
        },
        {
          img: require("../../assets/home/icon_ltqp.png"),
          style: {
            width: "37px",
            height: "38px",
          },
          title: "聊天气泡",
          content:
            "支持聊天气泡，可以有多种皮肤进行选择，让聊天更加有趣和生动。",
        },
        {
          img: require("../../assets/home/icon_hbgn.png"),
          style: {
            width: "42px",
            height: "50px",
          },
          title: "红包功能",
          content:
            "支持红包功能，分为个人红包和群聊红包，群聊红包包含手气红包、普通红包和专属红包。",
        },
        {
          img: require("../../assets/home/icon_sbgl.png"),
          style: {
            width: "37px",
            height: "41px",
          },
          title: "设备管理",
          content:
            "支持设备管理功能，可以查看登录账号的设备号，可以对设备号进行管理。",
        },
        {
          img: require("../../assets/home/icon_zmgx.png"),
          style: {
            width: "43px",
            height: "39px",
          },
          title: "桌面共享",
          content:
            "支持桌面共享功能，用户可以共享屏幕、文档、软件等内容，实现更加高效的沟通和协作。",
        },
        {
          img: require("../../assets/home/icon_zdhf.png"),
          style: {
            width: "47px",
            height: "43px",
          },
          title: "自动回复",
          content:
            "支持自动回复功能，用户可以设置自动回复消息，更好地处理聊天信息和高效沟通。",
        },
        {
          img: require("../../assets/home/icon_aqsz.png"),
          style: {
            width: "44px",
            height: "40px",
          },
          title: "安全设置",
          content:
            "支持安全设置功能，用户可以设置应用锁屏密码，加强账户的安全性。",
        },
        {
          img: require("../../assets/home/icon_zzjg.png"),
          style: {
            width: "44px",
            height: "44px",
          },
          title: "组织架构",
          content: "支持组织架构功能，支持多级部门和职位，方便企业管理。",
        },
        {
          img: require("../../assets/home/icon_qybg.png"),
          style: {
            width: "54px",
            height: "46px",
          },
          title: "企业办公",
          content:
            "支持企业办公功能，为企业提供考勤请假、任务管理、企业网盘等办公功能。",
        },
        {
          img: require("../../assets/home/icon_yyjc.png"),
          style: {
            width: "47px",
            height: "47px",
          },
          title: "应用集成",
          content:
            "支持集成第三方独立应用，例如在线商城、知识付费、OA办公等系统。",
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.advantage-module {
  padding-top: 68px;
  .advantage-module-content {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      margin-bottom: 68px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content-item {
        margin-bottom: 50px;
        .item-detail {
          height: 153px;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          background-color: #fff;
          display: flex;
          align-items: center;
          padding: 0 15px;
          img {
            margin-right: 15px;
          }
          .detail {
            text-align: left;
            width: 290px;
            .detail-title {
              font-size: 24px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            .detail-content {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 1600px) {
//   .item-detail {
//     padding: 0 13px !important;
//     img {
//       margin-right: 13px !important;
//     }
//   }
// }
// @media screen and (max-width: 1440px) {
//   .item-detail {
//     width: 330px !important;
//     padding: 0 10px !important;
//   }
// }
</style>