
<template>
  <div class="module">
    <div class="module-content">
      <div class="title">三项技术保障</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in securityList"
          :key="index"
        >
          <img :src="item.img" alt="" :class="item.class" />
          <div class="content-item-title">{{ item.title }}</div>
          <div class="content-item-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
export default {
  data() {
    return {
      securityList: [
        {
          img: require("../../assets/home/img_sjcks.png"),
          title: "上架超快速",
          class:'image1',
          content:
            "提供一站式上架服务,实现安卓苹果应用市场快速上架,我们有专业的苹果4.3解决方案上架通过率达100%",
        },
        {
          img: require("../../assets/home/img_xxcwd.png"),
          title: "消息超稳定",
          class:'image2',
          content:
            "基于Netty的服务端开发,支持万人群和百万级用户并发数,服务端+客户端多重消息防丢机制,保障消息通讯的稳定性",
        },
        {
          img: require("../../assets/home/img_cspclc.png"),
          title: "音视频超流畅",
          class:'image3',
          content:
            "语音通话、视频通话基于webrtc框架开发,通话清晰流畅,为用户提供更高品质的实时通话服务",
        },
      ],
    };
  },
};
</script>
  <style lang='scss' scoped>
.module {
  padding-top: 68px;
  .module-content {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      margin-bottom: 68px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 63px;
      .content-item {
        width: 280px;
        height: 400px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(55, 120, 253, 0.2);
        margin: 0 auto;
        padding: 0 36px;
        img {
          margin-top: 10px;
        }
        .image1{
            width:91px;
            height:131px;
        }
        .image2{
            width:100%;
            height:131px;
        }
        .image3{
            width:324px;
            height:149px;
            margin-left:-70px ;
        }
        .content-item-title {
          font-size: 24px;
          margin-bottom: 18px;
        }
        .content-item-content {
          text-align: left;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>