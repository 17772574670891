
<template>
  <div class="module gray">
    <div class="module-content">
      <div class="title">行业解决方案</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in solutionList1"
          :class="item.class"
          :key="index"
        >
          <div class="content-item-title">{{ item.title }}</div>
        </div>
      </div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in solutionList2"
          :class="item.class"
          :key="index"
        >
          <div class="content-item-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
export default {
  data() {
    return {
      solutionList1: [
        {
          class: "backgroundImage1",
          title: "泛娱乐社交解决方案",
        },
        {
          class: "backgroundImage2",
          title: "教育行业解决方案",
        },
        {
          class: "backgroundImage3",
          title: "社交电商解决方案",
        },
      ],
      solutionList2: [
        {
          class: "backgroundImage4",
          title: "游戏娱乐解决方案",
        },
        {
          class: "backgroundImage5",
          title: "医疗行业解决方案",
        },
        {
          class: "backgroundImage6",
          title: "招聘行业解决方案",
        },
      ],
    };
  },
};
</script>
  <style lang='scss' scoped>
.module {
  padding-top: 68px;
  .module-content {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      margin-bottom: 68px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      padding-bottom: 22px;
      .content-item {
        width: 380px;
        height: 210px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        background-position: center center;
        background-size: 100%;
        transition: all .4s ease;
        .content-item-title {
          font-size: 25px;
          font-weight: 400;
          margin-bottom: 18px;
        }
      }
      .content-item:hover{
        background-size: 130%;
      }
      .backgroundImage1 {
        background-image: url("../../assets/home/img_hyjjfa_1.png");
      }
      .backgroundImage2 {
        background-image: url("../../assets/home/img_hyjjfa_2.png");
      }
      .backgroundImage3 {
        background-image: url("../../assets/home/img_hyjjfa_3.png");
      }
      .backgroundImage4 {
        background-image: url("../../assets/home/img_hyjjfa_4.png");
      }
      .backgroundImage5 {
        background-image: url("../../assets/home/img_hyjjfa_5.png");
      }
      .backgroundImage6 {
        background-image: url("../../assets/home/img_hyjjfa_6.png");
      }
    }
  }
}
// @media screen and (max-width: 1600px) {
//   .content-item {
//     width: 404px !important;
//     height: 228px !important;
//   }
// }
// @media screen and (max-width: 1440px) {
//   .content-item {
//     width: 350px !important;
//     height: 208px !important;
//   }
// }
</style>