<template>
  <div class="module">
    <div class="module-content">
      <div class="title">企业私有化部署</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in privatizationList"
          :key="index"
        >
          <img :src="item.img" :style="item.style" />
          <div class="content-item-title">{{ item.title }}</div>
          <div class="content-item-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      privatizationList: [
        {
          img: require("../../assets/home/img_aqxgg.png"),
          style: {
            width: "216px",
            height: "196px",
          },
          title: "安全性更高",
          content:
            "私有化部署的即时通讯系统在数据存储和传输方面更加安全，因为企业可以拥有对敏感数据的全面控制和管理，而不必担心数据泄露和安全问题。",
        },
        {
          img: require("../../assets/home/img_xzxgd.png"),
          style: {
            width: "198px",
            height: "198px",
          },
          title: "选择性更多",
          content:
            "私有化部署有多种成熟稳定版本可供选择，可以根据企业的特定需求进行定制，因此能够更好地满足企业的具体通讯需求。",
        },
        {
          img: require("../../assets/home/img_xjbgy.png"),
          style: {
            width: "148px",
            height: "188px",
          },
          title: "性价比更优",
          content:
            "私有化部署只需要一定的初期投资，企业可以通过自主控制系统来降低运营成本，并避免向第三方提供商支付数额可观的订阅费用。",
        },
        {
          img: require("../../assets/home/img_ycxgh.png"),
          style: {
            width: "222px",
            height: "200px",
          },
          title: "隐私性更好",
          content:
            "私有化部署支持公有云和局域网，可以保障系统性能的稳定，企业拥有对用户数据的完全控制权，可以更好地保护数据隐私和安全。",
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.module {
  padding-top: 68px;
  .module-content {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      margin-bottom: 68px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      padding-bottom: 63px;
      .content-item {
        width: 260px;
        height: 482px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(55, 120, 253, 0.2);
        padding: 0 22px;
        img {
          margin-top: 10px;
        }
        .content-item-title {
          font-size: 24px;
          margin-bottom: 18px;
        }
        .content-item-content {
          text-align: left;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
// @media screen and (max-width: 1600px) {
//   .content-item {
//     // img {
//     //   width: 186px !important;
//     //   height: 166px !important;
//     // }
//   }
// }
// @media screen and (max-width: 1440px) {
//   .content-item {
//     padding: 0 16px !important;
//     // img {
//     //   width: 166px !important;
//     //   height: 146px !important;
//     // }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .content-item {
//     padding: 0 12px !important;
//     // img {
//     //   width: 146px !important;
//     //   height: 126px !important;
//     // }
//   }
// }
</style>