import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsView.vue')
  },
  {
    path: '/versionIntroduction',
    name: 'versionIntroduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/VersionIntroductionView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: import.meta.env.BASE_URL,
  routes
})

export default router
